import React from "react";
import PaymentMethod from "./PaymentMethod";
import {CREDITCARD, DIRECTDEBIT} from "../utils/constants";

function PaymentMethodList(props, state) {
    const {paymentMethod, compactUIEnabled} = props;

    return (
        <div className="payment-method-list">
            <div className="label">Select payment method</div>
            <div className="payment-method-list-wrapper">
                <div className="payment-method-list-wrapper-wrapper" style={{columns: `${compactUIEnabled ? 2 : 1} auto`}}>
                {props.paymentMethods.map((method) => (
                    <PaymentMethod
                        showFees={props.showFees}
                        key={method.payment_method}
                        id={method.payment_method}
                        selectedPaymentMethod={paymentMethod}
                        disabled={getDisableFlag(method)}
                        onClick={() => props.onSelect(method)}
                    />
                ))}
            </div>
            </div>
        </div>
    );
}

function getDisableFlag(method) {
    return !(method.enabled);
}

export default PaymentMethodList;
