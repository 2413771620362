import React, {useState} from "react";
import LoadingSpinner from "./LoadingSpinner";

const PaymentIFrame = ({webPageUrl}) => {
    const [isLoading, setLoading] = useState(true);

    return <div style={{marginTop: "16px"}}>
        {isLoading && <LoadingSpinner/>}
        <iframe id="payment-page-iframe"
                src={webPageUrl}
                frameBorder="0"
                width="100%"
                height="940px"
                onLoad={() => setLoading(false)}
        />
    </div>
}

export default PaymentIFrame;