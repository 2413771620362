import React from "react";
import _ from "lodash";
import "./App.scss";
import ErrorPage from "./components/ErrorPage";
import LoadingSpinner from "./components/LoadingSpinner";
import PageContent from "./components/PageContent";
import PaymentSummary from "./components/PaymentSummary";
import SelectedPaymentDrawer from "./components/SelectedPaymentDrawer";
import {isMobile} from "./utils/CommonUtils";
import {initializePayment, loadPaymentInfo, loadPaymentMethods} from "./utils/RequestHandlers";
import {
  ALIPAY,
  CHINAPAYMENTS,
  CREDITCARD,
  DIRECTDEBIT,
  LINK_ACCESS_TOKEN,
  UNIONPAY,
  WECHATPAY
} from "./utils/constants";


class PaymentApp extends React.Component {
  handleWindowResize = () => {
    this.setState({ isMobile: isMobile() });
  };

  constructor(props) {
    super(props);
    this.state = {
      // flags
      isMobile: isMobile(),
      isLoading: true,
      currentView: "payment-method-selection",
      isDrawerShown: false,
      isPaymentInfoLoaded: false,
      isPaymentMethodsLoaded: false,
      error: false,
      // data
      paymentInfo: null,
      paymentMethods: [],
      paymentMethod: null,
      paymentTransactionId: null,
      paymentFrameUrl: null,
      paymentQRCode: null,
      paymentWXCodeURL: null,
      processedFee: null,
      cardNumber: null,
      errorMessage: null,
      isResidentialFlow: false
    };
  }

  loadHostedPaymentElementsScript() {
    // need hpfOptions declared in global scope,
    // prior to loading hpf script
    window.hpfOptions = null;
    const head = document.querySelector("head");
    const script = document.createElement("script");
    script.src = process.env.REACT_APP_HOSTED_PAYMENTS_URLL;
    script.async = true;
    head.appendChild(script);
  }

  getReturnUrl() {
    let returnUrl;
    const urlParams = window.location.search.substr(1);
    for (let param of urlParams.split("&")) {
      if (param.startsWith("return_url")) {
        returnUrl = param.split("=")[1];
      }
    }
    return returnUrl;
  }

  setFaviconAndTitle(paymentInfo, isResidentialFlow) {
    const favicon = getFaviconEl();
    if (isResidentialFlow) {
      document.title = "Residential Hub";
    } else {
      const paymentInfoTitle = paymentInfo.paymentInfo.merchant.page_configuration.title;
      if (paymentInfoTitle) {
        document.title = paymentInfoTitle;
      } else {
        document.title = "Unilodge Payment Portal";
      }
      const paymentInfoFavicon = paymentInfo.paymentInfo.merchant.page_configuration.favicon_href;
      if (paymentInfoFavicon) {
        favicon.href = paymentInfo.paymentInfo.merchant.page_configuration.favicon_href;
      } else {
        favicon.href = "images/unilodge-favicon.png";
      }
    }
  }

  isResidentialFlow() {
    const domainName = window.location.href.slice(0, window.location.href.indexOf("?")); 
    return domainName.includes("residential");
  }

  async componentDidMount() {
    window.addEventListener("resize", this.handleWindowResize.bind(this));
    this.loadHostedPaymentElementsScript();
    const isResidentialFlow = this.isResidentialFlow();
    try {
      const params = window.location.search.substr(1);
      const paymentInfo = await loadPaymentInfo(params);
      sessionStorage.setItem(LINK_ACCESS_TOKEN, paymentInfo.paymentInfo.access_token);
      const paymentMethodsFeeList = await loadPaymentMethods(paymentInfo.paymentInfo.access_token, paymentInfo.paymentInfo.gross_amount);

      this.setFaviconAndTitle(paymentInfo, isResidentialFlow);
      const paymentMethods = {
        isPaymentMethodsLoaded: true,
        paymentMethods: this.getPaymentMethodList(paymentInfo.paymentInfo.merchant.payment_methods),
      };

      this.setState({
        ...paymentInfo,
        ...paymentMethods,
        isLoading: false,
        paymentMethodsFeeList: paymentMethodsFeeList.feeList,
        isResidentialFlow,
      });
    } catch (error) {
      this.setState({
        error: true,
        isLoading: false,
      });
    }
  }

  getPaymentMethodList(paymentMethods) {

    const isCreditCardDisabled = this.isPaymentOptionDisabled(paymentMethods, CREDITCARD);
    const isDirectDebitDisabled = this.isPaymentOptionDisabled(paymentMethods, DIRECTDEBIT);
    const isChinaPaymentsDisabled = this.isPaymentOptionDisabled(paymentMethods, CHINAPAYMENTS);

    const eligiblePaymentMethods = [];
    if (!isCreditCardDisabled) {
      eligiblePaymentMethods.push({payment_method: CREDITCARD, enabled: true});
    }

    if (!isDirectDebitDisabled) {
      eligiblePaymentMethods.push({payment_method: DIRECTDEBIT, enabled: true});
    }

    if (!isChinaPaymentsDisabled) {
      eligiblePaymentMethods.push({payment_method: WECHATPAY, enabled: true});
      eligiblePaymentMethods.push({payment_method: ALIPAY, enabled: true});
      eligiblePaymentMethods.push({payment_method: UNIONPAY, enabled: true});
    }

    return eligiblePaymentMethods;
  }

  isPaymentOptionDisabled(paymentMethods, code) {
    const entryList = paymentMethods.filter(method => method.payment_method_code === code);
    if (entryList && entryList.length > 0) {
      return !entryList[0].enabled;
    } else {
      return false;
    }
  }

  isChinaPaymentsEnabled(paymentMethods) {
    return paymentMethods.filter(
        method => method.payment_method_code === CHINAPAYMENTS &&
        method.enabled === true
    ).length > 0;
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
  }

  getCardNumber() {
    return this.state.cardNumber;
  }

  onPollingError(errorMessage) {
    this.setState({
      error: true,
      errorMessage
    });
  }

  onCardNumberReceived(cardNumber) {
    console.log(`Card number: ${cardNumber}`)
    this.setState({
      cardNumber: cardNumber
    });
  }

  getProcessedFee() {
    return this.state.processedFee;
  }

  onProcessedFeeCalculated(processedFee) {
    console.log(`Processed fee calculated ${processedFee}`)
    this.setState({
      processedFee: processedFee
    });
  }

  onPaymentMethodSelect(paymentMethod) {
    let appliedFee = null;
    if (paymentMethod && paymentMethod.payment_method === DIRECTDEBIT) {
      var results = this.state.paymentMethodsFeeList.find(fee => fee.payment_method === DIRECTDEBIT);
      if (results) {
        appliedFee = results.processing_fee;
      }
    }
    if (paymentMethod && paymentMethod.payment_method === ALIPAY) {
      var results = this.state.paymentMethodsFeeList.find(fee => fee.payment_method === ALIPAY);
      if (results) {
        appliedFee = results.processing_fee;
      }
    }
    if (paymentMethod && paymentMethod.payment_method === WECHATPAY) {
      var results = this.state.paymentMethodsFeeList.find(fee => fee.payment_method === WECHATPAY);
      if (results) {
        appliedFee = results.processing_fee;
      }
    }
    if (paymentMethod && paymentMethod.payment_method === UNIONPAY) {
      var results = this.state.paymentMethodsFeeList.find(fee => fee.payment_method === UNIONPAY);
      if (results) {
        appliedFee = results.processing_fee;
      }
    }

    this.setState({
      paymentMethod: paymentMethod,
      isDrawerShown: false,
      processedFee: appliedFee,
      currentView: "payment-method-selection",
      paymentFrameUrl: ''
    });
  }

  onPaymentMethodProceed() {
    this.setState({
      isLoading: true,
    });
    initializePayment(
        this.state.paymentInfo.access_token,
        this.state.paymentMethod
    )
        .then((response) => {
          if (!_.isEmpty(response.paymentTransactionId)) {
            this.setState({
              ...this.state,
              isLoading: false,
              isDrawerShown: false,
              currentView: "payment-form",
              paymentQRCode: response.paymentQRCode,
              paymentWXCodeURL: response.paymentWXCodeURL,
              paymentFrameUrl: response.paymentFrameUrl,
              paymentTransactionId: response.paymentTransactionId,
            });
          } else {
            this.setState({
              ...this.state,
              error: true,
              isLoading: false
            });
          }
        });
  }

  closeDrawer() {
    this.setState({ isDrawerShown: false, paymentMethod: null });
  }

  getPageContent() {}

  render() {
    const {
      currentView,
      isDrawerShown,
      paymentMethod,
      paymentMethods,
      paymentInfo,
      isLoading,
      isMobile,
      paymentTransactionId,
      paymentFrameUrl,
      paymentQRCode,
      paymentWXCodeURL,
      error,
      errorMessage,
      processingFee,
      isResidentialFlow
    } = this.state;

    return (
        <>
          {isLoading && <LoadingSpinner isFullScreen="true" />}

          {error && <ErrorPage customErrorMessage={errorMessage} />}

          {!error && paymentInfo && paymentMethods && (
              <div className={`payment-page-wrapper ${isLoading ? "loading" : ""}`}>
                {paymentInfo && (
                    <div className="payment-summary">
                      <div className="heading">
                        <span>{paymentInfo.merchant.name}</span>
                        {!isResidentialFlow && <img src={paymentInfo.merchant.brand_image} height="50px"/>}
                      </div>

                      <PaymentSummary
                          paymentIdValue={paymentInfo.reference_id}
                          paymentIdLabel={paymentInfo.reference_display_name}
                          paymentAmount={paymentInfo.gross_amount}
                          paymentUserFullName={this.getPaymentUserFullName(paymentInfo)}
                          paymentUserEmail={paymentInfo.customer.email}
                          paymentMethod={
                            currentView === "payment-form" ? paymentMethod : null
                          }
                          displayLineItems={paymentInfo.display_line_items}
                          lineItems={paymentInfo.line_items || []}
                          onGetProcessedFee={this.getProcessedFee.bind(this)}
                          getCardNumberCall={this.getCardNumber.bind(this)}
                      />
                    </div>
                )}

                {paymentMethods && (
                    <div className="payment-page-content">
                      <PageContent
                          currentView={currentView}
                          paymentMethods={paymentMethods}
                          isLoading={isLoading}
                          paymentInfo={paymentInfo}
                          paymentMethod={paymentMethod}
                          paymentTransactionId={paymentTransactionId}
                          paymentFrameUrl={paymentFrameUrl}
                          paymentQRCode={paymentQRCode}
                          paymentWXCodeURL={paymentWXCodeURL}
                          onPaymentMethodSelect={this.onPaymentMethodSelect.bind(this)}
                          onProceed={this.onPaymentMethodProceed.bind(this)}
                          onProcessedFeeCalculated={this.onProcessedFeeCalculated.bind(this)}
                          processingFee={this.state.processingFee}
                          onGetProcessedFee={this.getProcessedFee.bind(this)}
                          onCardNumberReceivedCall={this.onCardNumberReceived.bind(this)}
                          getCardNumberCall={this.getCardNumber.bind(this)}
                          returnUrl={this.getReturnUrl()}
                          onPollingError={this.onPollingError.bind(this)}
                      />
                    </div>
                )}

                <div className="page-footer">
                  <img src="assets/novatti_logo.svg" alt="Novatti" width="80" />
                  <div>Novatti Pty Ltd © {new Date().getFullYear()}</div>
                </div>
              </div>
          )}

          {/*
          todo move this to a component poratal
          ---
          portal can be opened using ReactDom.createPortal(<Component />, domNode);
          but closing mechanism is not working properly (when clicked on backdrop)
          same behavior will be needed for the modal as well
          */}
          {isMobile && isDrawerShown && paymentMethod && paymentInfo && (
              <SelectedPaymentDrawer
                  paymentMethod={paymentMethod}
                  paymentInfo={paymentInfo}
                  onProceed={this.onPaymentMethodProceed.bind(this)}
                  onExit={this.closeDrawer.bind(this)}
                  processingFee={this.state.processingFee}
                  onGetProcessedFee={this.getProcessedFee.bind(this)}
                  getCardNumberCall={this.getCardNumber.bind(this)}
              />
          )}
        </>
    );
  }

  getPaymentUserFullName(paymentInfo) {
    return [paymentInfo.customer.first_name, paymentInfo.customer.last_name].filter(Boolean).join(' ');
  }
}

function getFaviconEl() {
  return document.getElementById("favicon");
}

export default PaymentApp;
